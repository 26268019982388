<template>
  <div class="pad-home">
    <HeaderBar
      :title="title"
      :showLeft="true"
    ></HeaderBar>
    <div
      class="waitDriver"
      v-if="orderDetail"
    >
      <!-- background detail -->

      <div :class="bgClassObj">
        <!-- 顶部信息  -->
        <!-- 订单状态不为1或者订单状态为6并且订单司机已接单 -->
        <div v-if="status > 1">
          <!-- order-step -->
          <div class="driverTips">{{ statusTxt[status - 1] }}</div>
          <!-- startoff time -->
          <div class="startoffTime">
            <i :class="[status < 6 ? 'startoff-icon' : 'startoff-icon-finish']"></i>
            <span v-if="time == 1">立即出发</span>
            <span v-if="time == 2">{{ orderDetail.NewAppointmentTime }}</span>
          </div>
          <!-- driver info -->
          <div
            class="driverInfo"
            v-if="status < 6 && status > 1"
          >
            <div class="driver-image">
              <img
                :src="imgUrl"
                alt
              />
            </div>
            <div class="driver-des">
              <div class="driver-name">{{ orderDetail.PartnerName }}<a v-if="VehicleName">({{ VehicleName }})</a>
              </div>
              <div class="driver-stars">
                <div class>5.0</div>
                <div class="star-list">
                  <i
                    class="icon-star"
                    v-for="(item, index) in starsNum2"
                    :key="index"
                  ></i>
                </div>
              </div>
            </div>
            <!-- 进入极光IM 聊天界面 -->
            <div
              class="driver-new"
              @click="toIm"
            >
              <span v-if="Amsgnums > 0">{{ Amsgnums }}</span>
            </div>

            <a
              class="driver-tel"
              :href="'tel:' + orderDetail.Tel"
            ></a>
          </div>
          <!-- if status equals 5 the order is finished and the driver's tel hidden-->
          <div
            class="driverInfo"
            v-if="status > 5"
          >
            <div class="driver-image">
              <img
                :src="imgUrl"
                alt
              />
            </div>
            <div class="driver-des-finish">
              <div class="driver-name-finish">
                {{ orderDetail.PartnerName }}<a v-if="VehicleName">({{ VehicleName }})</a>
              </div>
              <div class="driver-stars">
                <div class>5.0</div>
                <div class="star-list">
                  <i
                    class="icon-star"
                    v-for="(item, index) in starsNum2"
                    :key="index"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 订单状态为1或者订单状态为6而且司机未接单 -->
        <div v-if="status == 1">
          <!-- order-step -->
          <div class="driverTips wait-margin">{{ statusTxt[status - 1] }}</div>
          <!-- startoff time -->
          <div class="startoffTime">
            <i :class="[status < 6 ? 'startoff-icon' : 'startoff-icon-finish']"></i>
            <span>{{ orderDetail.NewAppointmentTime }}</span>
          </div>
        </div>
      </div>
      <!-- order info -->
      <div class="order-info">
        <div class="order-type">
          <div :class="[
              'order-type-info',
              {
                'order-type-trip': type == 1,
                'order-type-designated': type == 2,
                'order-type-errand': type == 3,
                'order-type-shopping': type == 4,
              },
            ]">
            {{
              type == 1
                ? "专车"
                : type == 2
                ? "代驾"
                : type == 3
                ? "取送件"
                : "代购"
            }}
          </div>
          <div :class="[
              'order-type-info',
              'order-intime',
              {
                'order-time-now': time == 1,
                'order-time-appointment': time == 2,
              },
            ]">
            {{ time == 1 ? "即时订单" : "预约订单" }}
          </div>
        </div>
        <!-- <div class="order-title"
             v-if="type==3||type==4">
          帮我把一份文件送到我家来帮我把一份文件送到我家来
        </div>-->
        <div class="order-route">
          <div class="order-address order-startAddress">
            {{ orderDetail.StratAddress }}
          </div>
          <div
            class="order-address order-transAddress"
            v-for="(item, index) in orderDetail.AfterAddress"
            :key="index"
          >
            {{ item.city }}
          </div>
          <div class="order-address order-startAddress">
            {{ orderDetail.EndAddress }}
          </div>
        </div>
        <div class="order-number">
          <div>订单号码：{{ orderDetail.OrderNumberID }}</div>
        </div>
        <div
          class="remark"
          v-if="orderDetail.Rebak"
        >
          <div class="remark-content">
            <div>备注信息：{{ orderDetail.Rebak }}</div>
            <div
              class="remark-imgs"
              v-if="
                orderDetail.UploadImg != null && orderDetail.UploadImg != ''
              "
            >
              <div
                v-for="(img, index) in orderDetail.UploadImg.split(',')"
                :key="index"
                class="remark-imgs-item"
                @click="
                  showImagePreview(orderDetail.UploadImg.split(','), index)
                "
              >
                <!-- <img :src="img"
                alt="">-->
                <van-image
                  width="1.3rem"
                  height="1.3rem"
                  fit="cover"
                  :src="img"
                />
              </div>
            </div>
          </div>
          <div class="remark-imgs"></div>
        </div>
        <div class="order-price">
          <div>
            <div
              class="order-tip"
              v-if="time == 2"
            >
              小费 ${{ orderDetail.Gratuity }}
            </div>
            <div class="order-coupon">优惠券 -${{ orderDetail.Discount }}</div>
          </div>

          <div class="order-cost">
            <span>最后</span>
            <span class="cost-dollar">${{ orderDetail.PayAmount - orderDetail.Discount }}</span>
            <span>(¥{{
                Math.floor(
                  (orderDetail.PayAmount - orderDetail.Discount) * rateNum
                )
              }})</span>
          </div>
        </div>
        <div
          class="order-set"
          v-if="status < 5"
        >
          <div
            class="order-btn"
            @click.stop.prevent="shareRoute"
            v-if="status != 1"
          >
            分享行程
          </div>
          <div
            class="order-btn"
            @click.stop.prevent="addTip"
            v-if="status == 1"
          >
            追加小费
          </div>
          <div
            class="order-btn"
            @click.stop.prevent="popCacelReason"
            v-if="status < 5"
          >
            取消行程
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="State==1&&starsNum>0"> -->
    <!-- <div v-if="State==0||starNumber>0"> -->
    <div
      class="commentbottonBox"
      v-if="status == 6 || state == 6"
    >
      <div
        class="commentbotton"
        @click="addStar"
        v-if="starNumber == 0"
      >
        立即评价
      </div>
      <div
        class="commentcontent"
        v-else
      >
        <div class="commentStar">
          <van-rate
            v-model="starsNum"
            :size="25"
            :count="5"
            color="#F5A623"
            void-color="#CCCCCC"
            readonly
          />
        </div>
        <div class="commentcontenttxt">{{ commentContent }}</div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
    <!-- rate -->
    <van-popup
      v-model="show2"
      position="bottom"
      :style="{ height: '20%' }"
      closeable
      close-icon-position="top-right"
    >
      <div class="driver-rate">
        <div class="rated">
          <div class="rated-title">
            <div class="rated-title-txt">匿名评价</div>
            <!-- <div class="rated-title-uname"></div> -->
          </div>
          <div class="rated-stars">
            <van-rate
              v-model="starsNum"
              :size="25"
              :count="5"
              color="#F5A623"
              void-color="#CCCCCC"
              @change="onChangeStar"
            />
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 结束 -->
    <van-popup
      v-model="show"
      position="bottom"
      :lock-scroll="true"
      :overlay="true"
    >
      <div class="rate-pop">
        <div
          class="rate-pop-close"
          @click.stop.prevent="closeRate"
        ></div>
        <div class="rate-pop-content">
          <div
            class="rate-pop-title"
            style="width: 130px; height: 80px"
          >
            匿名评价
          </div>
          <div class="rate-pop-stars">
            <van-rate
              v-model="starsNum"
              :size="25"
              :count="5"
              color="#F5A623"
              void-color="#CCCCCC"
              @change="onChangeStar2"
            />
          </div>
          <!-- <div class="rate-pop-starcontent">
                        <span v-if="starsNum==1">非常不满意，各方面都很差</span>
                        <span v-if="starsNum==2">不满意，比较差</span>
                        <span v-if="starsNum==3">一般，还需改善</span>
                        <span v-if="starsNum==4">比较满意，仍可改善</span>
                        <span v-if="starsNum==5">非常满意，无可挑剔</span>
          </div>-->

          <div
            class="rate-pop-starTagBox"
            v-if="starsNum > 0"
          >
            <div class="rate-pop-tagList">
              <div
                class="rate-pop-tagItem"
                :class="{ active: tagList.includes(tag.id) }"
                v-for="tag in tags"
                :key="tag.id"
                @click.stop.prevent="chooseTag(tag.id)"
              >
                {{ tag.txt }}
              </div>
            </div>
          </div>
          <div
            class="rate-pop-customComment"
            v-if="code2 > 1"
          >
            <textarea
              name="customComment"
              v-model="commentContent"
              id="customComment"
              placeholder="其他想说的(只有浣熊平台可见,司机不可见)"
              :adjust-position="false"
              @blur="inputLoseFocus()"
            ></textarea>
          </div>
          <div
            class="submitRate"
            :class="{ active: starsNum > 0 }"
            @click.stop.prevent="comment"
          >
            匿名提交
          </div>
          <div
            class="blacklist"
            style="display: none"
          >
            <div class="blacklist-tips">不再坐该司机的车</div>
            <div class="blacklist-btn">加入黑名单</div>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- add tips -->
    <van-popup
      v-model="showTips"
      :overlay="true"
      :lock-scroll="true"
      class="tipsBox-pop"
    >
      <div class="tipsBox">
        <div class="tipsTitle">
          <div
            class="tipsCancel"
            @click="cancelTips"
          >取消</div>
          <div class="tipsTit">小费($)</div>
          <div
            class="tipsConfirm"
            @click="coformTips"
          >确定</div>
        </div>
        <div class="tips-input">
          <input
            type="number"
            step="1"
            placeholder="请输入小费额度($)"
            v-model="userTips"
          />
        </div>
      </div>
    </van-popup>
    <!-- 取消原因弹框 -->
    <van-popup
      v-model="showCancelReasons"
      :overlay="true"
      :lock-scroll="true"
      class="order-cancel-pop"
    >
      <div class="order-cancel-title">取消原因</div>
      <div class="order-cancel-container">
        <div class="order-cancel-tags">
          <div
            :class="[
              userCancelReasons == o.id
                ? 'order-cancel-tagItemActive'
                : 'order-cancel-tagItem',
            ]"
            v-for="o in cancelReasons"
            :key="o.id"
            @click="selectTag(o)"
          >
            {{ o.reason }}
          </div>
        </div>
        <div class="order-cancel-customTxt">
          <textarea
            name
            id
            cols="30"
            rows="10"
            placeholder="其他原因（最多输入40个字）"
            maxlength="40"
            v-model="customReason"
            class="order-cancel-txtarea"
          ></textarea>
        </div>
      </div>
      <div
        class="order-cancel-confirBtn"
        @click="cancelRoute"
      >确定</div>
    </van-popup>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import { Rate, Popup, Toast, Dialog, ImagePreview, Image } from "vant";
import config from "../../service/httpConfig";
import $ from "jquery";
import "signalr";
import requestUrl from "@/service/requestUrl";
import {
  getOrderDetailByOrderId,
  huanxiong_chat,
  addTips,
  cancelOrder,
  updateComment,
  queryComment,
  newgetmsg,
} from "@/service/";
import { mapState, mapActions, mapMutations } from "vuex";
import wechat from "../../lib/wechat";
import { inputBlur } from "@/mixins/inputBlur";
import globalVal from "../../store/modules/global";
import { setStore, removeStore, getStore } from "../../lib/storage";
import { store } from "../../store/store";

export default {
  name: "wait",
  mixins: [inputBlur],
  components: {
    HeaderBar,
    [Rate.name]: Rate,
    [Popup.name]: Popup,
    [Dialog.name]: Dialog,
    [Image.name]: Image,
  },
  data() {
    return {
      VehicleName: "",
      newsnum: 0,
      Amsgnums: 0,
      starNumber: 0,
      rated: false,
      // rateStars: 0,
      starsNum: 0,
      starsNum2: 5,
      // driverInfo: '',
      commentContent: "",
      show: false,
      showTips: false,
      show2: false,
      count: 0,
      count2: 0,
      code2: 2,
      state1: true,
      state2: false,
      tagList: [],
      newmsglist: [],
      LabelInfo: "",
      queryCommentData: "",
      tags: [
        {
          id: 0,
          txt: "车辆肮脏凌乱",
        },
        {
          id: 1,
          txt: "开车不稳不专心",
        },
        {
          id: 2,
          txt: "车内有异味",
        },
        {
          id: 3,
          txt: "强行索要小费",
        },
        {
          id: 4,
          txt: "司机恶意骚扰",
        },
        {
          id: 5,
          txt: "车辆信息不符",
        },
      ],
      // msg: '',
      orderDetail: "",
      userTips: "",
      //   status: 0,
      statusTxt: [
        "等待司机接单",
        "等待司机响应",
        "司机已知本单,正在赶来",
        "司机到达预约地，等待乘客上车",
        "正在前往目的地",
        "行程已结束",
        "行程已取消",
        "订单已过期",
        "订单被拒",
      ],
      timer: null,
      state: 1,
      testTime: "",
      showCancelReasons: false,
      cancelReasons: [
        {
          id: 0,
          reason: "司机拒绝接单",
        },
        {
          id: 1,
          reason: "自身计划有变",
        },
        {
          id: 2,
          reason: "太久无人接单",
        },
        {
          id: 3,
          reason: "司机突发情况不来",
        },
        {
          id: 4,
          reason: "填写信息有误",
        },
        {
          id: 5,
          reason: "司机不接电话",
        },
      ],
      customReason: "",
      userCancelReasons: -1,

      titleTxt: [
        [
          "等待接单",
          "等待接驾",
          "等待接驾",
          "等待接驾",
          "前往目的地",
          "行程结束",
          "行程结束",
          "行程结束",
          "行程结束",
        ],
        [
          "等待接单",
          "等待接驾",
          "等待接驾",
          "等待接驾",
          "前往目的地",
          "行程结束",
          "行程结束",
          "行程结束",
          "行程结束",
        ],
        [
          "等待接单",
          "等待取件",
          "等待取件",
          "等待取件",
          "等待送件",
          "订单已完成",
          "订单已完成",
          "订单已完成",
          "订单已完成",
        ],
        [
          "等待接单",
          "等待购物",
          "等待购物",
          "等待购物",
          "等待送货",
          "订单已完成",
          "订单已完成",
          "订单已完成",
          "订单已完成",
        ],
      ],
      // index: 0
    };
  },
  computed: {
    ...mapState({
      rateNum: ({ globalVal }) => globalVal.rateNum,
      msglist: ({ globalVal }) => JSON.parse(getStore("msglist")),
      msgnums: ({ globalVal }) => JSON.parse(getStore("msgnums")),
    }),

    orderId() {
      // 订单Id
      return this.$route.params.orderId;
    },
    type() {
      // type:订单类型:1.出行，2.代驾，3.取送件，4.代购
      return this.$route.params.type;
    },
    time() {
      //time:1.及时订单 2.预约单
      return this.$route.params.time;
    },
    status() {
      //status:1.等待司机接单 2.司机已接单，订单未开始 3.司机接驾中 4.订单行程进行中 5.订单结束
      let _status = this.$route.params.status
        ? this.$route.params.status
        : this.status;
      console.log("_status:", _status);
      if (_status >= 5) {
      }
      return _status;
    },
    typeContent() {
      return true;
    },
    bgClassObj() {
      return {
        "wait-bg": true,
        "bg-trip": this.type == 1 && this.status != 6,
        "bg-designated": this.type == 2 && this.status != 6,
        "bg-errand": this.type == 3 && this.status != 6,
        "bg-shopping": this.type == 4 && this.status != 6,
        "bg-finish": this.status > 5,
        white: this.status < 6,
        black: this.status > 6,
      };
    },
    imgUrl() {
      return require(`../../assets/logo.png`);
    },
    images() {
      return this.orderDetail && this.orderDetail.UploadImg.split(",");
    },
    title() {
      return this.titleTxt[this.type - 1][this.status - 1];
    },
  },
  methods: {
    ...mapMutations([
      "DEL_TRANSFER_LOCATION_ALL",
      "SET_END_PLACE",
      "SET_MSG_LIST",
    ]),
    ...mapActions(["exchangeRateHandler"]),
    shareRoute() {
      Toast("请点击右上角分享按钮onChangeStar分享");
    },
    async getnew(obj) {
      console.log("getnew:", obj);
      console.log("status4:", this.status);
      let _this = this,
        _msglist = JSON.parse(getStore("msglist"));
      let selectid = 0;

      newgetmsg(`${this.orderId}`, `${selectid}`).then((res) => {
        console.log("get_msg_res:", res);
        if (res) {
          let arr = JSON.parse(res.data),
            wait_msgnums = getStore("msgnums")
              ? Number(getStore("msgnums"))
              : 0;
          console.log("wait_arr_length:", arr.length);
          console.log(
            "wait_msgnums_length:",
            getStore("msgnums"),
            wait_msgnums
          );
          if (Number(arr.length) > wait_msgnums) {
            _this.Amsgnums = Number(arr.length) - wait_msgnums;
            if (_this.state == 6 || _this.state == 7) {
              console.log("1112");
              _this.Amsgnums = 0;
            }
          } else if (_this.state == 6 || _this.state == 7) {
            console.log("1112");
            _this.Amsgnums = 0;
          }
          console.log("Amsgnums:", _this.Amsgnums);
        }
      });
    },

    //数组对象去重
    distinct1(arr, key) {
      let obj = {};
      arr = arr.reduce((cur, next) => {
        obj[next[key]] ? "" : (obj[next[key]] = true && cur.push(next));
        return cur;
      }, []); //设置cur默认类型为数组，并且初始值为空的数组
      return arr;
    },
    toIm() {
      //   console.log("newmsglist:", this.newmsglist);
      //   console.log("msglist:", this.msglist);
      let msgArr2 = [];
      if (this.state > 5) {
      } else {
        setStore("im_Pmid", this.orderDetail.Pmid);
        setStore("im_Umid", this.orderDetail.Umid);
        this.$router.push(`/im`);
      }
    },
    onChange(index) {
      this.index = index;
    },
    onChangeStar(count) {
      this.count2 = count;
      if (this.count2 == 5) {
        this.tagList = [];
      }
      this.show2 = false;
      this.show = true;
    },

    onChangeStar2(count) {
      this.count2 = count;
      if (this.count2 == 5) {
        this.tagList = [];
      }
    },
    showImagePreview(images, position, timer) {
      const instance = ImagePreview({
        images,
        asyncClose: !!timer,
        closeOnPopstate: true,
        startPosition: typeof position === "number" ? position : 0,
      });
      if (timer) {
        setTimeout(() => {
          instance.close();
        }, timer);
      }
    },
    async cancelRoute() {
      const _this = this;
      if (this.status > 4) return;
      let cancelReasonTxt = [];
      // console.log(this.userCancelReasons)
      this.cancelReasons.map((item) => {
        if (this.userCancelReasons == item.id) {
          cancelReasonTxt.push(item.reason);
        }
      });
      cancelReasonTxt.push(this.customReason);
      let Anomalymanager = cancelReasonTxt.join(",");
      if (Anomalymanager == "") {
        Toast("请填写或者选择取消原因！");
        return;
      }
      clearInterval(this.timer);
      // loading start
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: "spinner",
        message: "loading...",
      });
      try {
        let res = await cancelOrder(this.orderId, this.status, Anomalymanager);
        console.log("cancelOrder", res);
        if (res.code == 1) {
          // loading close
          Toast.clear();
          this.SET_MSG_LIST([]);
          if (getStore("msglist")) {
            removeStore("msglist");
          }
          if (getStore("orderId")) removeStore("orderId");
          if (getStore("msgnums")) removeStore("msgnums");
          if (getStore("im_Pmid")) removeStore("im_Pmid");
          if (getStore("im_Umid")) removeStore("im_Umid");
          //   store.commit("SET_MSGNUMS", 0);
          this.$router.replace("/home");
        } else {
          Toast.clear();
          Toast(res.msg);
        }
      } catch (error) {
        // console.log(error);
        // Toast.clear()
      }
    },
    //取消行程
    popCacelReason() {
      // console.log(this.userCancelReasons);
      this.userCancelReasons = -1;
      this.showCancelReasons = true;

      // console.log(this.userCancelReasons);
    },
    selectTag(o) {
      // console.log(o);
      if (this.userCancelReasons == o.id) {
        this.userCancelReasons = -1;
      } else {
        this.userCancelReasons = o.id;
      }

      // 查找数组里面是否有此元素

      // let index = this.userCancelReasons.findIndex(item => {
      //   return item == o.id
      // })
      // if (index < 0) {
      //   // 数组无此元素
      //   this.userCancelReasons.push(o.id)
      // } else {
      //   // 有此元素
      //   this.userCancelReasons.splice(index, 1)
      // }
      // console.log(this.userCancelReasons)
      // if(){}
    },
    // comment () {
    //   this.show = true;
    // },
    closeRate() {
      this.show = false;
    },

    gethismsg() {
      let $this = this;
      console.log("requestUrl.messages:", requestUrl.messages);
      let conn = $.hubConnection(requestUrl.messages, {
        count: 500,
        begin_time: "2020-11-26 10:10:10",
        end_time: "2020-11-29 10:10:12",
      });
      $this.proxy = conn.createHubProxy("orderHub");
      // $this.getMsg();
      conn
        .start()
        .done((data) => {
          console.log("gethismsg", data);
        })
        .fail((err) => {
          console.log("gethismsg-err", err);
        });
    },
    // 获取订单信息
    async getOrderDetail(orderId) {
      console.log("this.state", this.state);
      if (this.state > 5) {
        clearInterval(this.timer);
        this.timer = null;
        this.SET_MSG_LIST([]);
        store.commit("SET_MSGNUMS", 0);
        removeStore("partner");
        removeStore("im_Pmid");
        removeStore("im_Umid");
        // return
      }
      let res = await getOrderDetailByOrderId(orderId);
      console.log("order_res:", res);
      this.newsnum++;
      this.status = res.data.State;

      if (res.data.State == 6) {
        console.log("status1:", this.status);
        this.Amsgnums = 0;
        clearInterval(this.timer);
        this.queryComment();
      }
      if (
        res.data.AfterAddress &&
        res.data.AfterAddress.length > 0 &&
        typeof res.data.AfterAddress == "string"
      ) {
        console.log("status2:", this.status);
        res.data.AfterAddress = JSON.parse(res.data.AfterAddress);
      }
      //   console.log("aaa", res);
      this.VehicleName = res.VehicleName ? res.VehicleName : "";
      this.orderDetail = res.data;
      //   console.log("newsnum:", this.newsnum);
      if (res.code == 1) {
        console.log("status3:", this.status);
        if (this.status > 1 && this.status < 6) {
          this.getnew(res);
        }

        // if (
        //   JSON.parse(getStore("partner")) &&
        //   JSON.parse(getStore("partner")).orderId
        // ) {
        //   if (JSON.parse(getStore("partner")).orderId != res.data.ID) {
        //     console.log("deldeldelele");
        //     // removeStore("partner");
        //     this.SET_MSG_LIST([]);
        //     store.commit("SET_MSGNUMS", 0);
        //   }
        // }
        if (res.iMPartner && res.iMPartner.AccountStr) {
          res.iMPartner.orderId = res.data.ID;
          setStore("partner", JSON.stringify(res.iMPartner));
        }
        // console.log("bbb:", res.VehicleName);
        this.VehicleName = res.VehicleName ? res.VehicleName : "";
        this.orderDetail = res.data;
        // console.log("orderDetai111111l:", this.orderDetail);
        // console.log('this.status:',this.status)
        if (this.orderDetail.State == 6 || this.orderDetail.State == 7) {
          this.SET_MSG_LIST([]);
          store.commit("SET_MSGNUMS", 0);
          this.SET_ORDER_ID("");
          this.$chat.loginout();
          this.Amsgnums = 0;
          if (getStore("msglist")) removeStore("msglist");
          if (getStore("orderId")) removeStore("orderId");
          if (getStore("msgnums")) removeStore("msgnums");
          if (getStore("im_Pmid")) removeStore("im_Pmid");
          if (getStore("im_Umid")) removeStore("im_Umid");
          removeStore("orderId");
        } else {
          //   this.imlogin();
        }

        if (this.status != res.data.State) {
          // console.log("111")
          this.state = res.data.State;
          // console.log(this.state)
          if (this.state == 6 || this.state == 7) {
            this.SET_MSG_LIST([]);
            store.commit("SET_MSGNUMS", 0);
            this.$chat.loginout();
            this.Amsgnums = 0;
            // this.show = true;
          } else {
            // this.imlogin();
            // this.init();
          }
          if (this.state > 5) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.$router.replace(
            `/wait/${this.orderId}/${res.data.OrderType}/${res.data.IsAssign}/${res.data.State}`
          );
        }
      }
    },
    // 初始化
    init() {
      let _this = this;
      this.$chat
        .init()
        .then((res) => {
          console.log("初始化成功");
          this.islogin();
          this.gethismsg();
        })
        .catch((err) => {
          console.log("初始化失败");
        });
    },
    imlogin() {
      // console.log('imlogin')
      if (this.$chat.isLogin()) {
        console.log("已登录");
        this.gethismsg();
        // this.register();
      } else {
        console.log("未登录");
        this.beforelogin();
        // this.login();
      }
    },
    beforelogin() {
      let _this = this;
      //   this.$chat.getJim();
      this.init();
      setTimeout(() => {
        // _this.login();
      }, 500);
    },
    // 登录
    login() {
      let _userInfo = JSON.parse(getStore("userInfo"));
      let form = {
        username: "UM" + _userInfo.Tel,
        password: "123456",
      };
      this.$chat
        .login(form)
        .then((res) => {
          console.log("登录成功", res);
          if (this.content) {
            this.sendSingleMsg();
          }
        })
        .catch((err) => {
          console.log("登录失败 err:", err);
          if (this.registerNum < 4) {
            this.register();
          }
        });
    },
    // add tips methods pop up the box
    addTip() {
      this.showTips = true;
    },
    addStar() {
      if (this.starsNum > 0) {
        this.show = true;
      }
      if (this.starsNum == 0) {
        console.log("12321313");
        this.Amsgnums = 0;
        this.show2 = true;
      }
    },
    // 取消输入小费
    cancelTips() {
      this.showTips = false;
    },
    // 确定小费
    coformTips() {
      this.addSomeTips();
      this.showTips = false;
      this.userTips = "";
    },
    // 添加小费
    async addSomeTips() {
      // let id = JSON.parse(getStore('userInfo')).ID
      if (!/^\+?[1-9][0-9]*$/.test(this.userTips)) {
        Toast("追加金额应为大于0的整数！");
        return;
      }
      let id = this.orderId;
      let userTips = this.userTips - 0;
      let DcCode =
        this.type == 1 ? "100104" : this.type == 2 ? "100105" : "100106";
      let res = await addTips(id, userTips, DcCode);
      if (res.code == 1) {
        this.getOrderDetail(this.orderId);
        // console.log(res)
      } else {
        Toast(res.msg);
      }
    },
    handleInterval() {
      clearInterval(this.timer);
      this.timer = null;
      if (this.state > 5) return;
      // console.log(this.state)
      // console.log(this)
      this.timer = setInterval(() => {
        this.getOrderDetail(this.orderId);
        this.getnew();
        if (this.state > 5) {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 5000);
    },
    // share route
    wxRegCallback() {
      this.wxShareTimeline();
      this.wxShareAppMessage();
    },
    // 分享朋友圈
    wxShareTimeline() {
      let _link = `${window.location.href.split("#")[0]}#/sharedroute/${
        this.orderId
      }`;
      _link = _link.replace(window.location.search, "");
      let option = {
        title: "你的好友分享行程给你", // 分享标题, 请自行替换
        desc: "你的好友分享行程给你", // 分享描述, 请自行替换
        link: _link, // 分享链接，根据自身项目决定是否需要split
        imgUrl: `${config.baseURL}/logo.png`, // 分享图标, 请自行替换，需要绝对路径
        success: () => {
          alert("分享成功");
        },
        error: () => {
          alert("已取消分享");
        },
      };
      // console.log(option)
      // 将配置注入通用方法
      wechat.ShareTimeline(option);
    },
    // 分享好友
    wxShareAppMessage() {
      let _link = `${window.location.href.split("#")[0]}#/sharedroute/${
        this.orderId
      }`;
      _link = _link.replace(window.location.search, "");
      let option = {
        title: "你的好友分享行程给你", // 分享标题, 请自行替换
        desc: "你的好友分享行程给你", // 分享描述, 请自行替换
        link: _link, // 分享链接，根据自身项目决定是否需要split
        imgUrl: `${config.baseURL}/logo.png`, // 分享图标, 请自行替换，需要绝对路径
        success: () => {
          alert("分享成功");
        },
        error: () => {
          alert("已取消分享");
        },
      };
      // console.log(option)
      // alert(JSON.stringify(option))
      // 将配置注入通用方法
      wechat.ShareAppMessage(option);
    },
    chooseTag(id) {
      if (this.count2 != 5) {
        let index = this.tagList.indexOf(id);
        if (index != -1) {
          this.tagList.splice(index, 1);
        } else {
          this.tagList.push(id);
        }
        let str = "";
        for (let i = 0; i < this.tags.length; i++) {
          for (let n = 0; n < this.tagList.length; n++) {
            if (this.tags[i].id == this.tagList[n]) {
              str += this.tags[i].txt + ",";
            }
          }
        }
        // console.log(str);
        this.LabelInfo = str;
      }
    },
    //获取评论内容
    async queryComment() {
      let res = await queryComment(this.orderId);
      console.log("queryComment_res:", res);
      this.queryCommentData = res;
      if (res.code == 1) {
        this.starNumber = res.data.StarCount;
        if (this.starNumber == 0) {
          this.addStar();
        }
        // this.StarCount=res.data.StarCount
        // if (res.data.State) {
        //   this.State = res.data.State;
        // }
        if (res.data.CommentContent !== "未评论") {
          this.commentContent = res.data.CommentContent;
        }

        this.starsNum = res.data.StarCount;
        if (res.data.StarCount == 0) {
          // this.show2= true;
        }
      }
    },
    inputLoseFocus() {
      window.scroll(0, 0);
    },
    comment() {
      this.postComment();
    },
    //提交评论
    async postComment() {
      if (this.starsNum > 0) {
        let res = await updateComment(
          this.orderDetail.Umid,
          this.orderDetail.Pmid,
          this.starsNum,
          this.orderDetail.ID,
          this.LabelInfo,
          this.commentContent
        );

        if (res.code == 1) {
          this.show = false;
          this.starNumber = this.starsNum;
          Toast(res.msg);
        } else {
          Toast(res.msg);
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.status > 5) {
        this.getOrderDetail(this.orderId);
      } else {
        this.getOrderDetail(this.orderId);
        this.handleInterval();
      }

      // this.exchangeRateHandler()//
    });
    if (this.status == 6) {
      this.queryComment();
    }

    if (!this.rateNum) {
      this.exchangeRateHandler();
    }
    let url = window.location.href.replace(window.location.search, "");
    wechat.wxRegister(url, this.wxRegCallback);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    sessionStorage.localStorage.removeItem("endAddress");
    sessionStorage.localStorage.removeItem("endGeo");
    this.DEL_TRANSFER_LOCATION_ALL();
    let place = {
      description: "",
      latlng: "",
    };
    this.SET_END_PLACE(place);
    this.timer = null;
  },
  destroyed() {
    clearInterval(this.timer);
    this.newsnum = 0;
    this.timer = null;
  },
  created() {
    // 极光IM连接
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";
.pad-home {
  padding-top: 94px;
}
.waitDriver {
  width: 690px;
  margin: 30px auto;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  .wait-bg {
    .size(690px, 360px);
    padding: 60px 30px 40px;
    box-sizing: border-box;
  }
  .bg {
    &-trip {
      background: url("../../assets/bg_trip.png") no-repeat;
      background-size: 690px 360px;
    }
    &-designated {
      background: url("../../assets/bg_designated.png") no-repeat;
      background-size: 690px 360px;
    }
    &-errand {
      background: url("../../assets/bg_errand.png") no-repeat;
      background-size: 690px 360px;
    }
    &-shopping {
      background: url("../../assets/bg_shopping.png") no-repeat;
      background-size: 690px 360px;
    }
    &-finish {
      background: url("../../assets/bg_finish.png") no-repeat;
      background-size: 690px 360px;
    }
  }
}
.wait-margin {
  margin: 40px 0 50px;
}
.driverTips {
  text-align: center;
  font-size: 36px;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  // color: rgba(255, 255, 255, 1);
  line-height: 50px;
}
.startoffTime {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 58px;
  text-align: center;
  font-size: 30px;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  // color: rgba(255, 255, 255, 1);
  line-height: 42px;

  .startoff-icon {
    display: inline-block;
    .size(26px, 26px);
    background: url("../../assets/white-clock@2x.png") 0 0 no-repeat;
    background-size: 26px 26px;
    margin-right: 18px;
  }
  .startoff-icon-finish {
    display: inline-block;
    .size(26px, 26px);
    background: url("../../assets/grey-clock@2x.png") 0 0 no-repeat;
    background-size: 26px 26px;
    margin-right: 18px;
  }
}

.driverInfo {
  display: flex;
  flex: 1;
  align-items: center;

  .driver {
    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      .size(80px, 80px);
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
      .bg(#fcfcfc);
      img {
        width: 50px;
      }
    }
    &-des {
      margin-left: 20px;
      margin-right: auto;
      &-finish {
        display: flex;
      }
    }
    &-name {
      font-size: 30px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      line-height: 42px;
      &-finish {
        font-size: 30px;
        font-family: "PingFangSC-Medium";
        font-weight: 500;
        line-height: 42px;
        margin-right: 20px;
      }
    }
    &-stars {
      display: flex;
      flex: 1;
      height: 34px;
      margin-top: 4px;
      align-items: center;
      font-size: 24px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(245, 166, 35, 1);
      line-height: 34px;
    }
    &-tel {
      .size(80px, 80px);
      background: url("../../assets/tel@2x.png") 0 0 no-repeat;
      background-size: 80px 80px;
    }
    &-new {
      .size(80px, 80px);
      background: url("../../assets/news.png") 0 0 no-repeat;
      background-size: 80px 80px;
      margin-right: 20px;
      span {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: red;
        z-index: 200;
        float: right;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
      }
    }
  }
}
.star-list {
  display: flex;
  flex: 1;
  align-items: center;
  height: 34px;

  .icon-star {
    display: block;
    .size(26px, 24px);
    margin-left: 6px;
    background: url("../../assets/star-big@2x.png") 0 0 no-repeat;
    background-size: 26px 24px;
  }
}
.remark {
  display: flex;
  margin: 30px 0 20px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: rgba(248, 248, 248, 1);
  border-radius: 4px;
  font-size: 20px;
  font-family: "PingFangSC-Light";
  font-weight: 300;
  color: rgba(153, 153, 153, 1);
  line-height: 28px;

  &-content {
    width: 100%;
  }

  &-imgs {
    display: flex;
    flex: 1;
    width: 100%;
    // justify-content: space-around;
    margin: 15px auto;
    &-item {
      width: 100px;
      height: 100px;
      overflow: hidden;
      margin-right: 20px;
      img {
        width: 100%;
        // height:100px;
      }
    }
  }
}
.order-info {
  padding: 20px 30px;

  .order-type {
    display: flex;
    flex: 1;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between;

    .order-type-info {
      .size(120px, 50px);

      border-radius: 4px;
      .text-center;
      font-size: 30px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;

      line-height: 50px;
    }
    &-trip {
      background: rgba(12, 170, 50, 0.1);
      color: rgba(12, 170, 50, 1);
    }
    &-designated {
      background: rgba(245, 166, 35, 0.1);
      color: rgba(245, 166, 35, 1);
    }
    &-errand {
      background: rgba(102, 155, 131, 0.1);
      color: rgba(102, 155, 131, 1);
    }
    &-shopping {
      background: rgba(205, 138, 80, 0.1);
      color: rgba(205, 138, 80, 1);
    }

    .order-intime {
      font-size: 24px;
    }
  }

  .order-title {
    margin: 20px 0 30px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 44px;
  }
}
.order-time {
  &-now {
    background: rgba(12, 170, 50, 0.1);
    color: rgba(12, 170, 50, 1);
  }
  &-appointment {
    background: rgba(245, 166, 35, 0.1);
    color: rgba(245, 166, 35, 1);
  }
}

.order-address {
  // height: 40px;
  padding-left: 48px;
  margin-bottom: 10px;
  font-size: 28px;
  font-family: "PingFangSC-Light";
  font-weight: 300;
  color: rgba(34, 34, 34, 1);
  line-height: 40px;
  // overflow: hidden;
  // text-overflow: ellipsis;
}
.order-startAddress {
  background: url("../../assets/start@2x.png") 0 6px no-repeat;
  background-size: 28px 28px;
}
.order-transAddress {
  background: url("../../assets/Oval Copy@2x.png") 0 6px no-repeat;
  background-size: 28px 28px;
}
.order- {
  background: url("../../assets/end@2x.png") 0 6px no-repeat;
  background-size: 28px 28px;
}
.order-number {
  height: 34px;
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 24px;
  font-family: "PingFangSC-Light";
  font-weight: 300;
  color: rgba(153, 153, 153, 1);
  line-height: 34px;
  display: flex;
  justify-content: space-between;
}

.order-price {
  display: flex;
  flex: 1;
  margin-bottom: 50px;
  align-items: center;
  justify-content: space-between;

  .order-coupon {
    font-size: 24px;
    font-family: "PingFangSC-Light";
    font-weight: 300;
    color: rgba(153, 153, 153, 1);
    line-height: 34px;
  }
  .order-tip {
    font-size: 24px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 34px;
  }

  .order-cost {
    font-size: 24px;
    font-family: "PingFangSC-Light";
    font-weight: 300;
    color: rgba(153, 153, 153, 1);

    .cost-dollar {
      .color(@brand-green);
      .fontSize(40px);
      font-family: "PingFangSC-Semibold";
      font-weight: 500;
    }
  }
}

.order {
  &-set {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }
  &-btn {
    .size(300px, 80px);
    margin-bottom: 30px;
    border-radius: 4px;
    background: rgba(238, 238, 238, 1);
    text-align: center;
    font-size: 30px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 80px;
  }
}
.driver-rate {
  .rate-title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    font-size: 30px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 42px;
  }
}
.comment {
  width: 440px;
  height: 80px;
  margin: 0 auto;
  border-radius: 4px;
  border: 2px solid rgba(12, 170, 50, 1);
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  color: rgba(12, 170, 50, 1);
}

.rated {
  width: 690px;
  height: 200px;
  margin: 30px auto;
  padding: 30px 30px 40px;
  background: rgba(255, 255, 255, 1);
  // box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  box-sizing: border-box;

  .rated-title {
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 40px;
    .rated-title-txt {
      font-weight: 600;
      text-align: center;
      margin-left: 245px;
      margin-top: -30px;
    }
    &-txt {
      margin-right: 20px;
      font-size: 36px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 50px;
    }
    &-uname {
      width: 68px;
      height: 40px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid rgba(204, 204, 204, 1);
      line-height: 40px;
    }
  }

  &-stars {
    margin: 0 auto;
    text-align: center;
  }
}

.rate-pop {
  position: relative;
  //   height: 984px;
  padding-top: 60px;
  box-sizing: border-box;

  &-close {
    .size(30px, 30px);
    position: absolute;
    right: 30px;
    top: 30px;
    background: url("../../assets/delete@2x.png") 0 0 no-repeat;
    background-size: 30px 30px;
  }

  &-title {
    box-sizing: border-box;
    margin-bottom: 40px;
    font-size: 36px;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    line-height: 50px;
    text-align: center;
  }

  &-stars {
    text-align: center;
  }

  &-starcontent {
    margin: 30px auto 40px;
    font-size: 24px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(245, 166, 35, 1);
    line-height: 34px;
    text-align: center;
  }

  &-starTagBox {
    width: 590px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
  }

  &-tagList {
    height: 70px;
    margin-right: -30px;

    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }

  &-tagItem {
    width: 280px;
    height: 70px;
    float: left;
    margin: 0 30px 20px 0;
    border-radius: 4px;
    border: 1px solid rgba(204, 204, 204, 1);
    font-size: 24px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 70px;
    text-align: center;
    box-sizing: border-box;
    &.active {
      background: #f5a623;
      border-radius: 2px;
      border: 1px solid #f5a623;
      color: #fff;
    }
  }

  &-customComment {
    width: 590px;
    height: 160px;
    margin: 0 auto;
    background: rgba(248, 248, 248, 1);
    border-radius: 4px;

    textarea {
      display: block;
      .size(100%, 100%);
      padding: 30px;
      box-sizing: border-box;
      border: none;
      background: rgba(248, 248, 248, 1);
      font-size: 24px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(204, 204, 204, 1);
      line-height: 34px;

      &::placeholder {
        font-size: 24px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(204, 204, 204, 1);
        line-height: 34px;
      }
    }
  }
}

.submitRate {
  width: 590px;
  height: 80px;
  margin: 20px auto;
  background: rgba(204, 204, 204, 1);
  border-radius: 4px;
  font-size: 30px;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 80px;
  text-align: center;
  &.active {
    background-color: #333;
  }
}

.blacklist {
  padding: 0 80px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background: rgba(248, 248, 248, 1);
  border-radius: 4px;

  &-tips {
    font-size: 24px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 34px;
  }

  &-btn {
    width: 160px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid rgba(151, 151, 151, 1);
    font-size: 24px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 50px;
    text-align: center;
  }
}

.tipsBox {
  width: 690px;
  height: 330px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  padding: 0 30px;
  box-sizing: border-box;

  &-pop {
    border-radius: 20px;
  }

  .tipsTitle {
    display: flex;
    flex: 1;
    margin: 60px 0;
    align-items: center;
    justify-content: space-between;
  }

  .tipsCancel {
    font-size: 36px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 50px;
  }
  .tipsTit {
    font-size: 36px;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    line-height: 50px;
  }
  .tipsConfirm {
    font-size: 36px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 50px;
  }

  .tips-input {
    width: 630px;
    height: 80px;
    background: rgba(238, 238, 238, 1);
    border-radius: 8px;

    input {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: none;
      text-align: center;
      background: rgba(238, 238, 238, 1);
      font-size: 30px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      // color: rgba(204, 204, 204, 1);
      line-height: 42px;
    }
  }
}

.order {
  &-cancel {
    &-pop {
      width: 690px;
      height: 886px;
      background: rgba(255, 255, 255, 1);
      border-radius: 20px;
      box-sizing: border-box;
    }

    &-title {
      margin-top: 60px;
      margin-bottom: 40px;
      font-size: 36px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      color: rgba(34, 34, 34, 1);
      line-height: 50px;
      text-align: center;
    }

    &-container {
      padding: 0 50px;
    }

    &-tags {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    &-tagItem {
      width: 280px;
      height: 80px;
      box-sizing: border-box;
      margin-bottom: 30px;
      border-radius: 4px;
      border: 2px solid rgba(204, 204, 204, 1);
      font-size: 28px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 80px;
      text-align: center;
    }

    &-tagItemActive {
      margin-bottom: 30px;
      width: 280px;
      height: 80px;
      background: rgba(12, 170, 50, 0.1);
      border-radius: 4px;
      font-size: 28px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(12, 170, 50, 1);
      line-height: 80px;
      text-align: center;
    }

    &-customTxt {
      margin-bottom: 40px;
      height: 254px;
      // padding: 30px;
      background: rgba(248, 248, 248, 1);
      border-radius: 4px;
    }

    &-txtarea {
      width: 100%;
      height: 100%;
      padding: 30px;
      border: none;
      background: rgba(248, 248, 248, 1);
      box-sizing: border-box;
      font-size: 28px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
    }

    &-confirBtn {
      height: 110px;
      border-top: 2px solid rgba(238, 238, 238, 1);
      font-size: 36px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(12, 170, 50, 1);
      line-height: 110px;
      text-align: center;
    }
  }
}
//评价
.commentbottonBox {
  width: 690px;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  margin: 50px auto 30px;

  .commentbotton {
    margin: 60px auto 0;

    width: 400px;
    height: 70px;
    border: 1px solid rgba(12, 170, 50, 1);
    text-align: center;
    line-height: 70px;
    color: rgba(12, 170, 50, 1);
    font-size: 30px;
    font-weight: 500;
  }
}
.commentcontent {
  .commentStar {
    padding-left: 210px;
    padding-top: 30px;
  }
}
.commentcontenttxt {
  text-align: center;
  line-height: 100px;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
}
.rate-pop-title {
  margin: 0 auto;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
}
.rate-pop-stars {
  margin-top: -70px;
  margin-bottom: 20px;
}
.rate-pop-tagItem {
  border: 2px solid #ccc !important;
}
</style>
